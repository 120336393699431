<template>
  <div class="container" id="main-page">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="container p-2">
          <div class="contact-us">
            <div class="row justify-content-center row-header">
              <div class="col-12 container-back pl-3">
                <div class="history-header">
                  <Back />
                  <span> Panduan Pengguna</span>
                </div>
              </div>
              <div class="col-12">
                <div class="card-profile w-100">
                  <div class="row">
                    <div class="col">
                      <div class="judul">
                        <p style="font-size: 1.2rem">
                          Panduan
                        </p>
                      </div>
                      <div class="accordion bantuan" id="accordionExample">
                        <div class="card">
                          <div class="card-header p-0" id="heading0">
                            <div class="mb-0 isi">
                              <button
                                href="javascript:void(0)"
                                class="btn btn-link btn-block d-flex justify-content-between collapsed py-3 bg-white"
                                data-toggle="collapse"
                                data-target="#collapse0"
                                aria-expanded="false"
                                aria-controls="collapse0"
                                type="button"
                              >
                                <div class="text-left align-items-center">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/icon-transaksi-tertunda.png"
                                    alt=""
                                    loading="lazy"
                                  />
                                  <span>Mengakses Website</span>
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    loading="lazy"
                                    class="m-0"
                                  />
                                </div>
                              </button>
                            </div>
                          </div>

                          <div
                            id="collapse0"
                            class="collapse"
                            aria-labelledby="heading0"
                            data-parent="#accordionExample"
                            style=""
                          >
                            <div class="card-body">
                              <p class="ket">
                                Untuk mengakses halaman member area, user dapat
                                mengakses alamat ini
                                <a
                                  href="https://www.sekolahdesain.id/teman-desain "
                                  >https://www.sekolahdesain.id/teman-desain</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header p-0" id="headingOne">
                            <h2 class="mb-0 isi">
                              <button
                                href="javascript:void(0)"
                                class="btn btn-link btn-block d-flex justify-content-between collapsed py-3 bg-white"
                                data-toggle="collapse"
                                data-target="#collapse1"
                                aria-expanded="false"
                                aria-controls="collapse1"
                              >
                                <div class="text-left align-items-center">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/icon-kendala-akun.png"
                                    alt=""
                                    loading="lazy"
                                  />
                                  <span>Login (Sudah Memiliki Akun)</span>
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    loading="lazy"
                                    class="m-0"
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>

                          <div
                            id="collapse1"
                            class="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                            style=""
                          >
                            <div class="card-body">
                              <ol>
                                <li>
                                  <span
                                    >Akses ke halaman
                                    <a
                                      href="https://www.sekolahdesain.id/teman-desain"
                                      >https://www.sekolahdesain.id/teman-desain</a
                                    >
                                    kemudian klik tombol masuk</span
                                  >
                                  <img
                                    src="/assets/images/bantuan/login/2.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>

                                <li>
                                  <span
                                    >Silakan Login di tombol “Masuk” pada
                                    Navigation Bar Landing Page</span
                                  >
                                  <img
                                    src="/assets/images/bantuan/login/1.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li>
                                  Login dengan email dan kata sandi User
                                </li>
                                <li>
                                  Klik “Mulai Belajar”, dan user akan masuk ke
                                  beranda platform sekolah desain
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header p-0" id="headingTwo">
                            <h2 class="mb-0 isi">
                              <a
                                href="javascript:void(0)"
                                class="btn btn-link btn-block d-flex justify-content-between collapsed py-3 bg-white"
                                data-toggle="collapse"
                                data-target="#collapse2"
                                aria-expanded="false"
                                aria-controls="collapse2"
                              >
                                <div class="text-left">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/konfirmasi_pembayaran.png"
                                    alt=""
                                    loading="lazy"
                                  />
                                  <span>Daftar (Belum Memiliki Akun)</span>
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    loading="lazy"
                                    class="m-0"
                                  />
                                </div>
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse2"
                            class="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordionExample"
                            style=""
                          >
                            <div class="card-body">
                              <ol>
                                <li>
                                  <span
                                    >Akses ke halaman
                                    <a
                                      href="https://www.sekolahdesain.id/teman-desain"
                                      >https://www.sekolahdesain.id/teman-desain</a
                                    >
                                    kemudian klik tombol masuk</span
                                  >
                                  <img
                                    src="/assets/images/bantuan/login/2.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li>
                                  Klik tombol “Daftar” di pojok kanan atas pada
                                  Navigation Bar Landing Page
                                </li>
                                <li>
                                  <span
                                    >Silahkan isi data diri seperti di bawah
                                    ini:</span
                                  >
                                  <img
                                    src="/assets/images/bantuan/login/3.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li>
                                  <span>
                                    Selanjutnya, User harus menyetujui Syarat &
                                    Ketentuan dan Kebijakan Privasi
                                    SekolahDesain.id dengan mengisi tanda
                                    centang pada box yang tersedia. Untuk
                                    menyelesaikan pendaftaran, User bisa klik
                                    gabung disini dan tunggu beberapa saat
                                    sampai akhirnya User bisa masuk ke Home Page
                                    Member Area SekolahDesain seperti gambar di
                                    bawah :
                                  </span>
                                  <img
                                    src="/assets/images/bantuan/login/4.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header p-0" id="headingThree">
                            <h2 class="mb-0 isi">
                              <button
                                class="btn btn-link btn-block collapsed d-flex justify-content-between"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <div class="text-left">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/icon-pembelian-kelas.png"
                                    alt=""
                                  />
                                  <span>Penukaran Voucher</span>
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    class="m-0"
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseThree"
                            class="collapse"
                            aria-labelledby="headingThree"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <ol>
                                <li>
                                  <span
                                    >Akses ke halaman
                                    <a
                                      href="https://www.sekolahdesain.id/teman-desain"
                                      >https://www.sekolahdesain.id/teman-desain</a
                                    >
                                    kemudian klik tombol masuk</span
                                  >
                                </li>
                                <li>
                                  Silakan Login di tombol “Masuk” pada
                                  Navigation Bar Landing Page atau “Daftar” jika
                                  belum memiliki akun
                                </li>
                                <li>
                                  <span
                                    >Setelah login, akan muncul tampilan beranda
                                    seperti ini, User dapat memasukan Kode
                                    Voucher pada tab “Tukarkan Kodemu!” seperti
                                    gambar di bawah</span
                                  >
                                  <img
                                    src="/assets/images/bantuan/login/4.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li>
                                  Klik “Tukarkan” dan kemudian jika berhasil
                                  akan muncul pop up berhasil dan kelas akan
                                  langsung muncul di menu “Kelasmu”
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header p-0" id="headingShopee">
                            <h2 class="mb-0 isi">
                              <button
                                class="btn btn-link btn-block collapsed d-flex justify-content-between"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseShopee"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <div class="text-left">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/icon-pembelian-kelas.png"
                                    alt=""
                                  />
                                  <span
                                    >Pembelian Kelas Melalui Platform (Setelah
                                    Login)</span
                                  >
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    class="m-0"
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseShopee"
                            class="collapse"
                            aria-labelledby="headingShopee"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <ol>
                                <li>
                                  Akses ke alamat website (
                                  <a
                                    href="https://platform.sekolahdesain.id/register"
                                    >https://platform.sekolahdesain.id/register</a
                                  >
                                  )
                                </li>
                                <li>
                                  <span>Login pada Navigation Bar </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas/1.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <span>
                                    Setelah berhasil masuk akan muncul tampilan
                                    beranda yang berisi macam-macam kelas yang
                                    sedang tersedia dan juga menu kategori kelas
                                    pada sisi kiri beranda. Klik tombol “Beli
                                    Kelas”
                                  </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas/2.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <span>
                                    Setelah klik “Beli Kelas”, user akan menuju
                                    Landing Page kelas yang dipilih, lalu klik
                                    “DAFTAR SEKARANG” untuk menuju Checkout Page
                                    pembelian kelas
                                  </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas/3.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <span>
                                    Setelah klik “Daftar Sekarang”, user akan
                                    masuk ke CheckOut Page, User harus
                                    mencentang kelas yang akan dibeli dan
                                    memilih salah satu metode pembayarannya
                                    seperti di bawah ini:
                                  </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas/4.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <p>
                                    Scroll ke bawah pada section Ringkasan
                                    Pembelian, lalu masukkan kode kupon (jika
                                    ada) pada kolom yang tersedia dan klik “Cek”
                                    agar harga terpotong. Klik tombol
                                    “Lanjutkan” untuk melakukan pembayaran
                                  </p>
                                  <div class="text-center">
                                    <img
                                      class="h-100 mx-auto"
                                      src="/assets/images/bantuan/pembelian-kelas/5.png"
                                      alt=""
                                    />
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    User akan menuju ke Page Konfirmasi
                                    Pembayaran, dan wajib untuk mengupload bukti
                                    pembayarannya serta mengisi data yang
                                    diperlukan seperti dibawah ini
                                  </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas/6.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  Setelah User mengirim bukti pembayaran, tunggu
                                  hingga 1x24 jam agar pembayaran diverifikasi
                                  admin. Jika sudah diverifikasi, akan muncul
                                  notif dan juga kelas sudah dapat diakses pada
                                  menu “Kelasku”
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header p-0" id="headinBlibli">
                            <h2 class="mb-0 isi">
                              <button
                                class="btn btn-link btn-block collapsed d-flex justify-content-between"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseBlibli"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <div class="text-left">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/icon-pembelian-kelas.png"
                                    alt=""
                                  />
                                  <span
                                    >Pembelian Kelas Melalui Homepage (Sebelum
                                    Login)</span
                                  >
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    class="m-0"
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseBlibli"
                            class="collapse"
                            aria-labelledby="headingBlibli"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <ol>
                                <li>
                                  <span>
                                    Akses ke link home page Sekolah Desain
                                    <a
                                      href="https://www.sekolahdesain.id/teman-desain "
                                      >https://www.sekolahdesain.id/teman-desain
                                    </a>
                                    dan akan muncul tampilan dibawah, Klik
                                    tombol “Lihat Kelasnya”, user akan menuju ke
                                    halaman kelas-kelas yang tersedia. Terdapat
                                    3 kategori kelas yakni Kelas Terbaru, Semua
                                    Kelas, dan Kelas Prakerja
                                  </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas-lp/1.png"
                                    alt=""
                                  />
                                </li>

                                <li>
                                  <span>
                                    Cara untuk order kelas dan untuk mengetahui
                                    materi apa yang akan didapatkan saat membeli
                                    kelasnya, klik “Lihat Kelas” yang
                                    selanjutnya mengarah ke Landing Page kelas.
                                    Disini, user dapat melihat deskripsi kelas
                                    dan fasilitas yang akan didapatkan.
                                  </span>
                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas-lp/2.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <span>
                                    Untuk membeli kelasnya, klik tombol yang
                                    berwarna kuning / "Gabung Sekarang" yang
                                    nantinya akan mengarahkan user pada kolom
                                    pengisian data diri.
                                  </span>

                                  <img
                                    class="w-100"
                                    src="/assets/images/bantuan/pembelian-kelas-lp/3.png"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <span>
                                    Isi data diri secara lengkap dan benar.
                                  </span>
                                  <div class="text-center">
                                    <img
                                      class="h-100 mx-auto"
                                      src="/assets/images/bantuan/pembelian-kelas-lp/4.png"
                                      alt=""
                                    />
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    Setelah mengisi, scroll ke bawah untuk
                                    mengetahui rincian pesanan. Klik “Ikuti
                                    Kelasnya Sekarang” untuk menuju halaman
                                    pembayaran.
                                  </span>
                                  <div class="text-center">
                                    <img
                                      class="h-100 mx-auto"
                                      src="/assets/images/bantuan/pembelian-kelas-lp/5.png"
                                      alt=""
                                    />
                                  </div>
                                </li>
                                <li>
                                  <span>
                                    Lakukan pembayaran sesuai nominal tagihan
                                    yang muncul seperti di bawah ini. Terdapat 2
                                    metode pembayaran, Bank BCA dan Bank
                                    Mandiri. User dapat memilih salah satu bank
                                    untuk membayarnya.
                                  </span>
                                  <div class="text-center">
                                    <img
                                      class="h-100 mx-auto"
                                      src="/assets/images/bantuan/pembelian-kelas-lp/6.png"
                                      alt=""
                                    />
                                  </div>
                                </li>
                                <li>
                                  Jika user sudah melakukan pembayaran, tunggu
                                  hingga Admin menghubungi melalui Whatsapp
                                  untuk memberikan kode voucher untuk ditukarkan
                                  menjadi kelas yang dibeli.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header p-0" id="heading4">
                            <h2 class="mb-0 isi">
                              <a
                                href="javascript:void(0)"
                                class="btn btn-link btn-block d-flex justify-content-between collapsed py-3 bg-white"
                                data-toggle="collapse"
                                data-target="#collapse4"
                                aria-expanded="false"
                                aria-controls="collapse4"
                              >
                                <div class="text-left">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/icon-belajar-di-sekolahdessain.png"
                                    alt=""
                                    loading="lazy"
                                  />
                                  <span>Belajar di Sekolah Desain</span>
                                </div>
                                <div class="text-right">
                                  <img
                                    src="https://www.sekolahdesain.id/assets/images/bantuan-down.png"
                                    alt=""
                                    loading="lazy"
                                    class="m-0"
                                  />
                                </div>
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse4"
                            class="collapse"
                            aria-labelledby="heading4"
                            data-parent="#accordionExample"
                            style=""
                          >
                            <div class="card-body">
                              <ol>
                                <li class="font-900">
                                  <p class="font-900">Komponen Kelas</p>
                                  <p>
                                    Untuk belajar di SekolahDesain.id, User
                                    dapat pergi ke kelas yang sudah ia miliki,
                                    bisa membeli sendiri ataupun dengan dana
                                    kartu Prakerja. User dapat menemukan kelas
                                    melalui Beranda di section “Jadwal Webinar”
                                    dan juga di menu “Kelasmu”. Di dalam kelas,
                                    User dapat menemukan tab detail kelas yaitu
                                    Video, Modul, Webinar, Quiz, dan Pencapaian.
                                    Tab ini lah yang digunakan User untuk
                                    melakukan proses belajar di
                                    SekolahDesain.id.
                                  </p>
                                </li>
                                <li class="font-900">
                                  <p class="font-900">Menonton Video Materi</p>
                                  <p>
                                    Salah satu syarat untuk menyelesaikan kelas
                                    prakerja di SekolahDesain.id adalah dengan
                                    menonton semua Video Materi. User dapat
                                    menemukan daftar video materi pada Tab
                                    Video. Setelah Tab Video diklik, video
                                    pertama akan langsung autoplay saat membuka
                                    Tab Video. User harus menonton video dari
                                    awal sampai akhir tanpa bisa skip untuk bisa
                                    dianggap selesai menonton video secara
                                    menyeluruh. Jika User sudah menonton video
                                    dari awal sampai akhir, akan muncul pop up
                                    “Video Berhasil Ditonton” dan status di
                                    samping durasi video pada akan muncul
                                    “Selesai”, lalu klik Lanjut untuk menuju
                                    video selanjutnya. Tonton semua video untuk
                                    memenuhi syarat kelulusan.
                                  </p>
                                  <img
                                    src="/assets/images/bantuan/belajar/1.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li class="font-900">
                                  <p class="font-900">Modul</p>
                                  <p>
                                    Modul juga merupakan salah satu syarat
                                    kelulusan kelas. User harus men-download
                                    modul-modul yang tersedia, dan saat modul
                                    telah di-download, status pada modul akan
                                    berubah menjadi “Sudah Dibaca” seperti pada
                                    gambar di bawah ini.
                                  </p>
                                  <img
                                    src="/assets/images/bantuan/belajar/2.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li class="font-900">
                                  <p class="font-900">Webinar</p>
                                  <p>
                                    Syarat kelulusan selanjutnya adalah menonton
                                    Live Webinar. User dapat mengakses Live
                                    Webinar pada tab Live Webinar di dalam
                                    kelas. Live Webinar di SekolahDesain.id
                                    menggunakan platform Zoom, sehingga User
                                    harus sudah memiliki akses ke Zoom terlebih
                                    dahulu, bisa dengan mengunduh di smartphone
                                    maupun install di PC Desktop/Laptop User.
                                    User dapat mengakses Webinar sesuai jadwal
                                    yang tercantum pada tab Webinar kelas
                                    tersebut dan juga di beranda. Untuk
                                    dinyatakan lulus, User setidaknya sudah
                                    mengikuti 1 kali Live Webinar selama
                                    mengikuti kelas prakerja di
                                    SekolahDesain.id.
                                  </p>
                                  <p>
                                    Selain live webinar, pada Tab Webinar juga
                                    terdapat section Rekaman Webinar. Apabila
                                    user tertinggal webinar, atau ingin
                                    mengulang kembali materi yang diajarkan oleh
                                    mentor pada saat webinar, User dapat
                                    menonton rekaman webinar yang telah tersedia
                                    pada section tersebut. Rekaman webinar akan
                                    muncul 1 hari setelah webinar.
                                  </p>
                                  <img
                                    src="/assets/images/bantuan/belajar/3.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>

                                <li class="font-900">
                                  <p class="font-900">Quiz</p>
                                  <p>
                                    Syarat kelulusan selanjutnya adalah
                                    mengerjakan Quiz dan Ujian. Untuk
                                    mengerjakan Quiz & Ujian, User dapat pergi
                                    ke tab Quiz dan klik “Kerjakan” . Quiz &
                                    Ujian merupakan soal untuk menguji sampai
                                    mana pemahaman User akan materi kelasnya
                                    dalam bentuk soal pilihan ganda. Untuk dapat
                                    dinyatakan lulus, User harus mencapai nilai
                                    80 untuk Quiz, dan nilai 60 untuk Ujian.
                                    Jika tidak mencapai nilai tersebut, User
                                    dapat mengulang kembali hingga mendapat
                                    nilai yang memenuhi syarat kelulusan.
                                  </p>
                                  <img
                                    src="/assets/images/bantuan/belajar/4.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                                <li class="font-900">
                                  <p class="font-900">Pencapaian</p>
                                  <p>
                                    Tab Pencapaian merupakan Tab yang berisi
                                    status pencapaian User untuk memenuhi
                                    seluruh persyaratan kelulusan kelas.
                                    Pencapaian disini terdiri atas video materi,
                                    modul, quiz, live webinar, dan ujian. User
                                    dapat dikatakan Lulus jika sistem sudah
                                    mendeteksi bahwa User sudah memenuhi syarat
                                    kelulusan dan status syarat berubah dari
                                    belum selesai menjadi selesai. Apabila user
                                    telah menyelesaikan seluruh syarat tersebut,
                                    maka status di sertifikat akan menjadi
                                    “Lulus” dan sertifikat dapat didownload.
                                  </p>
                                  <img
                                    src="/assets/images/bantuan/belajar/5.png"
                                    alt=""
                                    class="w-100"
                                  />
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup() {
    const collapses = reactive({
      collapse0: false,
      collapse1: false,
      collapse2: false,
      collapse3: false,
      collapse4: false,
    })

    return {
      collapses,
    }
  },
})
</script>
