
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup() {
    const collapses = reactive({
      collapse0: false,
      collapse1: false,
      collapse2: false,
      collapse3: false,
      collapse4: false,
    })

    return {
      collapses,
    }
  },
})
